import { createTheme } from "@mui/material";
import Axiforma from "../fonts/Axiforma-Black.ttf";
export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000",
      light: "#333333",
      dark: "#000000",
    },
    secondary: {
      main: "#FFFD5F",
      light: "#FFFD7F",
      dark: "#B2B142",
    },
    background: {
      paper: "#ffffff",
      default: "#e5e5e5",
    },
  },
  typography: {
    fontFamily: '"Axiforma", sans-serif',
    h1: {
      fontWeight: 300,
      fontSize: "6rem",
    },
    h2: {
      fontSize: 300,
      fontSize: "3.75rem",
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 400,
    },
    h4: {
      fontSize: "2.125rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1.6rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFFD5F",
          boxShadow: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#e5e5e5",
          boxShadow: "none",
          width: "100%",
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Axiforma],
      },
    },
  },
});
