import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandMoreIcon } from "../../assets";
import List from "@mui/material/List";

export default function Questions() {
  return (
    <Grid
      container
      sx={{ my: 5, pl: [1, 4, 6, 16.25], pr: [1, 4, 6, 14.75] }}
      rowSpacing={{ xs: 2, md: 4 }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: '"Circular Std",sans-serif',
            fontWeight: 700,
            fontSize: [21, 24, 32],
          }}
          gutterBottom
          component="div"
        >
          Questions? We have answers
        </Typography>
      </Grid>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            What is Flex-E-Rent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Flex+E+Rent is at the intersection of flexibility, affordability, and access. We offer membership subscriptions to a network of well-furnished apartments with no expensive upfront costs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            Who is Flex-E-Rent for?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            You! That work professional or recent graduate.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            What is Flex membership?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Everyone that finds a home through Flex+E+Rent is a Flex member. Your membership gives you access to our payment plans and other services provided by us or third-party vendors.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            What are my membership payments?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Your membership is free, excluding your rent, service charge, and booking fee.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            What are Flex+e+rent payment plans?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Flex+E+Rent payment plans are either monthly, quarterly or annually.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            What does my Membership Rent cover?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Your membership rent cover:
          </Typography>
          <List>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• Your living at the location."
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• LAWMA and refuse disposal fees."
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• Monthly water bill"
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• Monthly internet/wifi charges (unless otherwise stated)"
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• Monthly electricity charges (unless otherwise stated)"
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• VAT and other tax charges."
              />
            </ListItem>
            <ListItem disablePadding>
              {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: [10, 12],
                    fontWeight: 300,
                  },
                }}
                primary="• Access to the amenities at your location (unless otherwise stated)."
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            Where is Flex+e+rent available?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Flex+E+Rent is currently testing in Lagos city for now.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            How do I move in?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            Just bring in your luggage, show up and start living, that stress-free.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 600,
              fontSize: [12, 14],
            }}
          >
            what type of apartments & rooms does it have?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              fontWeight: 300,
              fontSize: [10, 12],
            }}
          >
            We have one-bedroom, two-bedroom apartments, studios, private rooms in shared, and multi-bedroom apartments available.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Grid>
  );
}
