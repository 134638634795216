import { React, useState } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  LandLordLandingPage,
  MessageIcon,
  IconD,
  IconE,
  IconF,
  ExpandMoreIcon,
  GettingStartedA,
  GettingStartedB,
  GettingStartedC,
  GettingStartedD,
  HeroC,
} from "../../assets";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "./styles.css";
// import required modules
import { FreeMode, Navigation } from "swiper";
import { InView } from "react-intersection-observer";

import { toLeft, toRight } from "../../utils/animation";
import LandLordGettingStartedForm from "../../Components/LandLordGettingStartedForm";
import { HashLink } from "react-router-hash-link";

function LandLords() {
  const [visibleIndices, setVisibleIndices] = useState([]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flex: "1 0 auto",
            minHeight: "100vh",
            background: `linear-gradient(to bottom, rgb(0, 0, 0,0.12),rgb(67, 67, 67,0.32)),url(${LandLordLandingPage}) no-repeat center center`,
            webkitBackgroundSize: "cover",
            mozBackgroundSize: "cover",
            oBackgroundSize: "cover",
            backgroundSize: "cover",
          }}
        >
          <Grid item container alignItems="center" xs={12}>
            <Grid
              container
              rowGap={4}
              sx={{ pl: [1, 4, 6, 16.25], pr: [1, null] }}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: '"Axiforma",sans-serif',
                    fontWeight: 900,
                    fontSize: [35, 35, 40, 60],
                    color: "#ffffff",
                    textAlign: { xs: "center", md: "start" },
                    lineHeight: 1,
                  }}
                  component="div"
                >
                  Maximize your <br className="big-screen-break" /> rental
                  income + let us handle the rest
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12} md={5}>
                <Typography
                  sx={{
                    fontFamily: '"Axiforma",sans-serif',
                    fontWeight: 700,
                    fontSize: [12, 14],
                    color: "#ffffff",
                    textAlign: { xs: "center", md: "start" },
                  }}
                  component="div"
                >
                  Partner with us and we'll do the heavy lifting. Get your
                  property leased and earn monthly guaranteed rent
                </Typography>
              </Grid>
              <Grid item xs={7} />

              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  justifyContent: { xs: "center", md: "flex-start" },
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <HashLink smooth to="/#landLord-getting-started">
                  <Button
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      "&.MuiButton-containedPrimary": {
                        fontSize: {
                          xs: "0.40rem",
                          md: "0.53rem",
                          lg: "0.75rem",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "#e5e5e5",
                      },
                      padding: { md: "16px 24px" },
                    }}
                    variant="contained"
                  >
                    Get Early Access
                  </Button>
                </HashLink>
              </Grid>
              <Grid item xs={6} />
            </Grid>
          </Grid>
        </Grid>
        <InView rootMargin="100px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid
                ref={ref}
                container
                alignItems="center"
                rowSpacing={4}
                sx={{ mb: 4, backgroundColor: "#FFFF001A", py: 8 }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                    pl: [1, 4, 6, 16.25],
                    pr: [1, 4, 6, 14.75],
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "Axiforma, sans-serif",
                        fontWeight: 700,
                        fontSize: [18, 20, 32, 48],
                        color: "#1D293F",
                        lineHeight: 1.25,
                      }}
                      component="div"
                    >
                      Flex+E+Rent is the perfect tenant.
                      <br className="big-screen-break" /> We pay you every month
                      for up to 3 years,
                      <br className="big-screen-break" /> guaranteed.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                    pl: [1, 4, 6, 16.25],
                    pr: [1, 4, 6, 14.75],
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Circular Std, sans-serif",
                      fontWeight: 300,
                      color: "#7C8087",
                      fontSize: [10, 14],
                    }}
                  >
                    Flex+E+Rent offers an exciting opportunity for owners
                    looking to maximize their rental income while attracting
                    desirable and vetted tenant.
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Swiper
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      900: { slidesPerView: 4, spaceBetween: 30 },
                    }}
                    grabCursor={true}
                    navigation
                    longSwipes={false}
                    spaceBetween={30}
                    freeMode
                    onTouchMove={(swiperInstance) => {
                      if (swiperInstance.visibleSlidesIndexes.length === 5) {
                        const slideItemsToBlur = [
                          swiperInstance.visibleSlidesIndexes[0],
                          swiperInstance.visibleSlidesIndexes[
                            swiperInstance.visibleSlidesIndexes.length - 1
                          ],
                        ];

                        setVisibleIndices(slideItemsToBlur);
                      } else {
                        setVisibleIndices([]);
                      }
                    }}
                    onReachBeginning={() => setVisibleIndices([])}
                    onReachEnd={() => setVisibleIndices([])}
                    modules={[FreeMode, Navigation]}
                    className="mySwiper"
                    watchSlidesProgress
                  >
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(0) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.3)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              pt: 2,
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flex: 1,
                                }}
                              >
                                <MessageIcon height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: [16, 16, 14, 16],
                                    fontWeight: 700,
                                  }}
                                >
                                  Onboard vetted tenants
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: [12, 12, 8, 12],
                                    fontWeight: 400,
                                  }}
                                >
                                  Our tenants are all vetted through our sales
                                  team using a variety of tools & background
                                  checks.
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(1) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.3)",

                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              pt: 2,
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flex: 1,
                                }}
                              >
                                <IconD height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: [16, 16, 14, 16],
                                    fontWeight: 700,
                                  }}
                                >
                                  Guaranteed Rent for up to 3-Years
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />

                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: [12, 12, 8, 12],

                                    fontWeight: 400,
                                  }}
                                >
                                  We eliminate delinquency risk, and remove
                                  vacancy risk and eviction risk, as applicable.
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(2) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.3)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              pt: 2,
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",

                                  justifyContent: "center",
                                  flex: 1,
                                }}
                              >
                                <MessageIcon height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: [16, 16, 14, 16],
                                    fontWeight: 700,
                                  }}
                                >
                                  Furnishing + décor set up
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box sx={{ display: "flex", flex: 1 }} />
                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: [12, 12, 8, 12],
                                    fontWeight: 400,
                                  }}
                                >
                                  We convert your property into a
                                  move-in ready home by expertly designing &
                                  furnishing your space. No out of pocket
                                  expenses required from the landlord.
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(3) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.3)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              pt: 2,
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flex: 1,
                                }}
                              >
                                <IconE height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: [16, 16, 14, 16],
                                    fontWeight: 700,
                                  }}
                                >
                                  Effortless Property Management
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box sx={{ display: "flex", flex: 1 }} />

                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: [12, 12, 8, 12],

                                    fontWeight: 400,
                                  }}
                                >
                                  Pre-vetted vendor partners, preventative
                                  scheduled maintenance services, 24/7 emergency
                                  response & on-call repair technicians.
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplate: "1fr / 1fr",
                          borderRadius: "10px",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {visibleIndices.includes(4) && (
                          <Box
                            gridColumn="1 / span 1"
                            gridRow="1 / span 1"
                            sx={{
                              backgroundColor: "rgb(247, 248, 250,0.3)",
                              zIndex: 2000,
                              display: { xs: "none", md: "block" },
                              borderRadius: "10px",
                            }}
                          />
                        )}

                        <Box
                          gridColumn="1 / span 1"
                          gridRow="1 / span 1"
                          sx={{ zIndex: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              pt: 2,
                              rowGap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flex: 1,
                                }}
                              >
                                <IconF height="35px" width="35px" />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#1D293F",
                                    fontSize: [16, 16, 14, 16],
                                    fontWeight: 700,
                                  }}
                                >
                                  Expert marketing + leasing
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  flex: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: '"Circular Std",sans-serif',
                                    color: "#7C8087",
                                    fontSize: [12, 12, 8, 12],
                                    fontWeight: 400,
                                  }}
                                >
                                  Let us do the heavy lifting to find great
                                  tenants at the best rate in the fewest number
                                  of days.
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  </Swiper>
                </Grid>
              </Grid>
            );
          }}
        </InView>
        <Grid
          container
          sx={{ my: 4, pl: [1, 4, 6, 16.25], pr: [1, 4, 6, 14.75] }}
        >
          <Grid
            item
            xs={12}
            container
            sx={{
              backgroundColor: " #FFFD5F",
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                px: [2, 4, 6, 8],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: [3, 3, 2],
                  alignItems: "center",
                  height: [100, 120, 150],
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Sen",
                    fontWeight: 600,
                    fontSize: [10, 12, 14, 21, 32],

                    color: "#000000",
                    letterSpacing: [1, 1, 1, 3],
                  }}
                >
                  Looking to rent out your property?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    textTransform: "initial",
                    "&.MuiButton-containedPrimary": {
                      fontSize: {
                        xs: "0.40rem",
                        md: "0.53rem",
                        lg: "0.75rem",
                      },
                    },
                  }}
                  variant="contained"
                >
                  Get Early Access
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <InView rootMargin="-200px 0px 100px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid
                ref={ref}
                container
                sx={{
                  mt: 8,
                  mb: 4,
                  pl: [1, 4, 6, 16.25],
                  pr: [1, 4, 6, 14.75],
                }}
                rowSpacing={{ xs: 4, md: 6 }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...(inView && {
                      animation: {
                        md: `${toRight} 3s ease 1s 1 backwards`,
                      },
                    }),
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Axiforma",sans-serif',
                      fontWeight: 700,
                      fontSize: [21, 24, 28, 50],
                      lineHeight: 1.2,
                    }}
                  >
                    Does your property qualify?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...(inView && {
                      animation: {
                        md: `${toLeft} 3s ease 1s 1 backwards`,
                      },
                    }),
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Circular Std",sans-serif',
                      fontWeight: 300,
                      color: "#7C8087",
                      fontSize: [10, 12, 14],
                    }}
                  >
                    We’re looking to bring on top-quality, furnished 
                    apartments or buildings in the areas named below that fit
                    the following criteria
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={8}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    sm={6}
                    rowSpacing={2}
                    sx={{
                      ...(inView && {
                        animation: {
                          md: `${toLeft} 3s ease 1s 1 backwards`,
                        },
                      }),
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: 460,
                        height: 406,
                        maxWidth: { xs: "100%", sm: 240, md: 350, lg: 460 },
                        maxHeight: { xs: 298, sm: 220, md: 316, lg: 406 },
                      }}
                      alt="The house from the offer."
                      src={require("../../assets/RoomImageA.png")}
                    />

                    <Grid container justifyContent="center" item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 400,
                          fontSize: [10, 12, 14],
                        }}
                      >
                        Newly-built or recently renovated apartment
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    alignItems="center"
                    sm={6}
                    rowSpacing={2}
                    sx={{
                      ...(inView && {
                        animation: {
                          md: `${toRight} 3s ease 1s 1 backwards`,
                        },
                      }),
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: 460,
                        height: 406,
                        maxWidth: { xs: "100%", sm: 240, md: 350, lg: 460 },
                        maxHeight: { xs: 298, sm: 220, md: 316, lg: 406 },
                      }}
                      alt="The house from the offer."
                      src={require("../../assets/RoomImageB.png")}
                    />

                    <Grid container justifyContent="center" item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 400,
                          fontSize: [10, 12, 14],
                        }}
                      >
                        Unfurnished or furnished apartment/unit/building
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </InView>

        <InView rootMargin="70px 0px 0px 0px">
          {({ inView, ref, entry }) => {
            return (
              <Grid
                container
                ref={ref}
                direction="column"
                justifyContent="center"
                spacing={4}
                sx={{
                  mt: 5,
                  backgroundColor: "#FFFF001A",
                  pl: [1, 4, 6, 16.25],
                  pr: [1, 4, 6, 14.75],
                }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    my: 6,
                    ...(inView && {
                      animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"Axiforma",sans-serif',
                      fontWeight: 700,
                      fontSize: [21, 24, 32],
                    }}
                    gutterBottom
                    component="div"
                  >
                    How to get started
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    mb: [10, 14],
                    justifyContent: { md: "center" },
                    ...(inView && {
                      animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                    }),
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(12,1fr)",
                        md: "repeat(4,1fr)",
                      },
                      gridTemplateRows: "min-content",
                      gap: { xs: "16px 0px", md: "16px 32px" },
                    }}
                  >
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <GettingStartedA width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 14,
                            }}
                          >
                            Send in a request
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Tell us about your property, we’ll crunch the
                            numbers and send a personalized offer that optimizes
                            your rental income. Your work is done -- leave the
                            rest to us.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <GettingStartedB width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Schedule assessment
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            We’ll reach out to answer any questions and schedule
                            an assessment to finalize your offer.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <GettingStartedC width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            We upgrade, design or furnish your space
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            Depending, we might perform quick upgrades, design or furnishing.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                      <Grid containter columns={{ md: 6 }}>
                        <Grid item xs={12}>
                          <GettingStartedD width={"40px"} height={"50px"} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            List property in the marketplace
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontFamily: '"Circular Std",sans-serif',
                              fontWeight: 300,
                              fontSize: 14,
                              color: "#7C8087",
                            }}
                          >
                            We execute our professional proprietary leasing
                            process and show your property 24/7 days a week
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} container justifyContent="center">
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: " #FFFD5F",
                        borderRadius: 2,
                        height: [100, 120, 150],
                        transform: [
                          "translateY(50px)",
                          "translateY(60px)",
                          "translateY(75px)",
                        ],
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          px: [2, 4, 6, 8],
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexGrow: [3, 3, 2],
                            alignItems: "center",
                            height: [100, 120, 150],
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Sen",
                              fontWeight: 600,
                              fontSize: [10, 12, 14, 21, 32],

                              color: "#000000",
                              letterSpacing: [1, 1, 1, 3],
                            }}
                          >
                            Learn how to maximize your rental income
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{
                              textTransform: "initial",
                              "&.MuiButton-containedPrimary": {
                                fontSize: {
                                  xs: "0.40rem",
                                  md: "0.53rem",
                                  lg: "0.75rem",
                                },
                              },
                            }}
                            variant="contained"
                          >
                            Get Early Access
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid> */}
              </Grid>
            );
          }}
        </InView>

        <Grid
          container
          sx={{
            // mt: ["55px", "65px", "80px"],
            flex: "1 0 auto",
            minHeight: "100vh",
            background: `linear-gradient(to bottom, rgb(0, 0, 0,0.12),rgb(67, 67, 67,0.32)),url(${HeroC}) no-repeat center center`,
            webkitBackgroundSize: "cover",
            mozBackgroundSize: "cover",
            oBackgroundSize: "cover",
            backgroundSize: "cover",
            mb: 8,
          }}
        />

        <Grid
          container
          sx={{ pl: [1, 4, 6, 16.25], pr: [1, 4, 6, 14.75] }}
          rowSpacing={{ xs: 2 }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Circular Std",sans-serif',
                fontWeight: 700,
                fontSize: [21, 24, 32],
              }}
              gutterBottom
              component="div"
            >
              Questions? We have answers
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Why partner with Flex+E+Rent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  There are a few primary reasons property owners & developers partner with Flex+E+Rent:
                </Typography>
                <List>
                  <ListItem disablePadding>
                    {/* <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon> */}
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: [10, 12],
                          fontWeight: 300,
                        },
                      }}
                      primary="1. Property owners/developers that want to enhance the flexibility of their asset(s) and increase the net operating income of the asset(s)."
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    {/* <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon> */}

                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: [10, 12],
                          fontWeight: 300,
                        },
                      }}
                      primary="   2. Property owners/developers that don't want to think about the painful, expensive vacancy (or delinquency) again."
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    {/* <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon> */}
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: [10, 12],
                          fontWeight: 300,
                        },
                      }}
                      primary="3. Property owners/developers that want the ease and freedom of having an aligned, professional partner to price, market, screen, lease, and collect rent."
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    {/* <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon> */}
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: [10, 12],
                          fontWeight: 300,
                        },
                      }}
                      primary="No management fees + no brokers fees"
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Who does Flex+E+Rent typically rent to?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  We are an equal housing opportunity provider that specializes
                  in furnished housing for young working professionals.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Is there any upfront cost to partner with Flex+E+Rent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  None. It's free of charge and just a few steps to get started. Fill in the signup form below the page, and support will contact you.
                </Typography>
                



              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  How do I request a guaranteed monthly rent offer from Flex+E+Rent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Fill in the signup form below the page, and support will contact you.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  How does Flex+E+Rent come up with the price for my property?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Pricing takes into consideration recent comparables from both furnished and unfurnished homes, as well as market rates of other rental housing providers.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  In what cities does Flex+E+Rent operate?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Flex+E+Rent serves the Lagos City area at the moment.- with more coming soon
                </Typography>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  What kind of spaces can I list on Flex+E+Rent Africa?
                </Typography>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [10, 12, 14],
                  }}
                >
                  Flex+E+Rent works with property owners and developers of studios, multi-bedrooms, multiple units, and entire buildings.
                </Typography>
                
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Do you work with apartment homes that are unfurnished or already furnished?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Yes, both
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Is Flex+E+Rent a property manager?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Yes. We offer the basic services you’d expect of a property manager: marketing, leasing, rent collection, etc. However, we go above and beyond with maintenance & repairs, brokerage services, guaranteed rent, the best-of-the-best tenants, and more.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  In what cities does Flex-E-Rent operate?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Flex-E-Rent serves the Lagos City area at the moment.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  How long does the average resident stay?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  The minimum stay is 30 days (a month).
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  What type of properties does Flex-E-Rent work with?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Flex+E+Rent works with all types of quality properties: a
                  single space e.g a spare bedroom, multiple family spaces like
                  multiple bedrooms in a home, or whole apartments,
                  single-family detached, townhomes, du/tri/quadplexes, and
                  condos. We typically look for units built or renovated within
                  the last 10 years.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon width={"16px"} height={"16px"} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 500,
                    fontSize: [12, 14],
                  }}
                >
                  Is Flex+E+Rent a property manager?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 300,
                    fontSize: [10, 12],
                  }}
                >
                  Yes, and no
                </Typography>
                <Typography
                  sx={{
                    fontFamily: '"Circular Std",sans-serif',
                    fontWeight: 400,
                    fontSize: [10, 12],
                  }}
                >
                  Yes, and no No, because property managers don’t guarantee you
                  market-rate rent, nor do they have aligned incentives to find
                  great tenants for your property. Plus, no property manager
                  offers the same flexible management plans as Flex+E+Rent. Yes,
                  because we offer the basic services you’d expect of a property
                  manager: marketing, leasing, rent collection, etc. However, we
                  go above and beyond with optional maintenance and repairs,
                  brokerage services, guaranteed rent, the best-of-the-best
                  tenants, and more.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            my: [8, 10, 12, 14],
            pl: [1, 4, 6, 16.25],
            pr: [1, 4, 6, 14.75],
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Axiforma",sans-serif',
                fontWeight: 700,
                fontSize: [21, 24, 32],
              }}
            >
              Do you work with apartment homes that are already furnished?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Axiforma",sans-serif',
                fontWeight: 300,
                fontSize: [16, 18],
              }}
            >
              No. Every Flex+E+Rent is specially designed and outfitted with
              furnishings that have been selected by our in-house design team to
              fit each unique floor plan.
            </Typography>
          </Grid> */}
        </Grid>
        <LandLordGettingStartedForm />
      </Box>
    </>
  );
}

export default LandLords;
