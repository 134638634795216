import React from "react";
import HomePage from "./HomePage";
import HelpPage from "./HelpPage";
import WhyFlexerent from "./WhyFlexerent";
import SaveExpenses from "./SaveExpenses";
import AllInclusiveBillLiving from "./AllInclusiveBillLiving";
import Questions from "./Questions";
import Grid from "@mui/material/Grid";
import { HeroB } from "../../assets";
import Box from "@mui/material/Box";
import TenantGettingStartedForm from "../../Components/TenantGettingStartedForm";

export default function LandingPage() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowX: "hidden",
        }}
      >
        <HomePage />
        <HelpPage />
        <WhyFlexerent />
        <Grid
          container
          sx={{
            my: 7,
            flex: "1 0 auto",
            minHeight: "100vh",
            background: `linear-gradient(to bottom, rgb(0, 0, 0,0.12),rgb(67, 67, 67,0.32)),url(${HeroB}) no-repeat center center`,
            webkitBackgroundSize: "cover",
            mozBackgroundSize: "cover",
            oBackgroundSize: "cover",
            backgroundSize: "cover",
          }}
        />
        <SaveExpenses />
        <AllInclusiveBillLiving />
        <Questions />
        <TenantGettingStartedForm />
      </Box>
    </>
  );
}
