import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { InView } from "react-intersection-observer";
import { toLeft, toRight } from "../../utils/animation";
import {
  VectorG,
  VectorH,
  VectorI,
  VectorJ,
  VectorK,
  VectorL,
  VectorM,
  VectorN,
} from "../../assets";

export default function AllInclusiveBillLiving() {
  return (
    <InView rootMargin="70px 0px 0px 0px">
      {({ inView, ref, entry }) => {
        return (
          <Grid
            container
            ref={ref}
            direction="column"
            spacing={4}
            sx={{ my: 5, pl: [1, 4, 6, 16.25], pr: [1, 4, 6, 14.75] }}
          >
            <Grid
              item
              xs={12}
              direction="column"
              container
              sx={{
                mt: 6,
                ...(inView && {
                  animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontWeight: 700,
                  fontSize: [21, 24, 32],
                }}
                gutterBottom
                component="div"
              >
                All-Inclusive Bill Living
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"Circular Std",sans-serif',
                  fontWeight: 400,
                  fontSize: [16, 18, 21],
                  color: "#7C8087",
                }}
                component="div"
              >
                Our homes come fully furnished and equipped with handpicked
                essentials. When you subscribe, You have complete access to
                these and more benefits than you can imagine. When you arrive,
                your Spleet home is warm, comfortable, and ready for living.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{
                mb: 8,
                justifyContent: { md: "center" },
                ...(inView && {
                  animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: {
                    xs: "repeat(12,1fr)",
                    md: "repeat(4,1fr)",
                  },
                  gridTemplateRows: "min-content",
                  gap: { xs: "16px 0px", md: "48px 64px" },
                }}
              >
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorG width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Power Supply
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Minimum of 12 hours
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorH width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Water
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          24/7 running water
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorI width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Gas
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Included in subscription
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorJ width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Estate Dues
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Included in subscription
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorK width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Cleaning
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Complete space cleaning
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorL width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Security services
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Included in subscription
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorM width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Waste Management
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Included in subscription
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      columnGap: [2, 4],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <VectorN width={"20px"} height={"30px"} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 600,
                            fontSize: 12,
                          }}
                        >
                          Amenities
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          sx={{
                            fontFamily: '"Axiforma",sans-serif',
                            fontWeight: 300,
                            fontSize: 14,
                            color: "#7C8087",
                          }}
                        >
                          Included in subscription
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </InView>
  );
}
