import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "@mui/material";
import TenantForm from "./TenantForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";
    
export default function TenantGettingStartedForm() {
  const spaceTypes = ["Shared", "Entire"];
  const housingUnits = [
    "Studio",
    "Apartment",
    "Flat",
    "Bungalow",
    "Duplex",
    "Miniflat",
    "Penthouse",
  ];
  const durations = ["Monthly", "Quarterly", "Bi-annual", "Annual"];
  const [spaceType, setSpaceType] = React.useState("Shared");
  const [housingUnit, setHousingUnit] = React.useState("Studio");
  const [duration, setDuration] = React.useState("Monthly");

  const postUrl = 'https://flexerentafrica.us17.list-manage.com/subscribe/post?u=9a2b34acf46fd84a75bfbf8f2&amp;id=890d36163e';

  const handleSpaceTypeChange = (event) => {
    setSpaceType(event.target.value);
  };
  const handleHousingUnitChange = (event) => {
    setHousingUnit(event.target.value);
  };
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };
  
  return (
    <Grid
      id="tenant-getting-started"
      sx={{
        my: 5,
        pl: [1, 4, 6, 16.25],
        pr: [1, 4, 6, 14.75],
        flex: "0 1 auto",
      }}
      rowSpacing={{ xs: 2, md: 4 }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: '"Circular Std",sans-serif',
            fontWeight: 700,
            fontSize: [21, 24, 32],
          }}
        >
          Get early Access
        </Typography>

      </Grid>
      <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                  <TenantForm 
                        status={status} 
                        message={message}
                        onValidated={formData => {
                          subscribe(formData)}}
                  />
                )}
      />




    </Grid>


  );

}


