import React from "react";
import landingPage from "../../assets/landingPage.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { HashLink } from "react-router-hash-link";

export default function HomePage() {
  return (
    <Grid
      container
      sx={{
        flex: "1 0 auto",
        // pt: ["48px", "48px", "48px", "64px"],

        height: ["calc(100vh - 48px)", "calc(100vh - 64px)"],
        backgroundColor: "#FFFD5F",
      }}
    >
      <Grid item container alignItems="center" xs={12} md={7}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            pl: [1, 4, 6, 16.25],
            pr: [1, null],
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Axiforma",sans-serif',
                fontWeight: 800,
                fontSize: [35, 40, 50, 60],

                textAlign: { xs: "center", md: "start" },
                lineHeight: 1,
              }}
              component="div"
            >
              Welcome to the
              <br className={"big-screen-break"} /> future of living.
              <br className={"big-screen-break"} /> Show up. Start living
            </Typography>
          </Grid>

          <Box sx={{ mb: [2, 2, 2, 4] }} />

          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: '"Axiforma",sans-serif',
                fontWeight: 400,
                fontSize: [12, 14, 16, 21],
                textAlign: { xs: "center", md: "start" },
              }}
              component="div"
            >
              Network of furnished apartments + Seamless processes
              <br className={"big-screen-break"} />
              Flexible leases, Minimum 30 day stays
            </Typography>
          </Grid>

          <Box sx={{ mb: [4, 4, 4, 8] }} />

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: { xs: "center", md: "flex-start" },
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <HashLink smooth to= "/#tenant-getting-started">
              <Button
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  "&.MuiButton-containedPrimary": {
                    fontSize: {
                      xs: "0.40rem",
                      md: "0.53rem",
                      lg: "0.75rem",
                    },
                  },
                  padding: { md: "16px 24px" },
                }}
                variant="contained"
              >
                Get Early Access
              </Button>
            </HashLink>
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        sx={{
          display: { xs: "none", md: "block" },
          postion: "relative",
          width: "100%",
          height: "100%",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: [null, null, 320, 420.2, 440.32],
            height: [null, null, 380, 500, 550.9],
            right: [null, null, 30, 80],
            bottom: 0,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              height: "100%",
            }}
            alt="Hand holding a phone"
            src={landingPage}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
