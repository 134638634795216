import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { InView } from "react-intersection-observer";
import { toLeft, toRight } from "../../utils/animation";
import {
  VectorA,
  VectorB,
  VectorC,
  VectorD,
  VectorE,
  VectorF,
} from "../../assets";
export default function WhyFlexerent() {
  return (
    <InView rootMargin="70px 0px 0px 0px">
      {({ inView, ref, entry }) => {
        return (
          <Grid
            container
            ref={ref}
            direction="column"
            spacing={4}
            sx={{ my: 5, pl: [1, 4, 6, 16.25], pr: [1, 4, 6, 14.75] }}
          >
            <Grid
              item
              xs={12}
              direction="column"
              container
              sx={{
                mt: 6,
                ...(inView && {
                  animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Axiforma",sans-serif',
                  fontWeight: 700,
                  fontSize: [21, 24, 32, 48],
                }}
                gutterBottom
                component="div"
              >
                Why Choose Flex-e-rent?
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"Axiforma",sans-serif',
                  fontWeight: 400,
                  fontSize: [16, 18, 21],
                  color: "#7C8087",
                }}
                component="div"
              >
                Subscription living.
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"Axiforma",sans-serif',
                  fontWeight: 400,
                  fontSize: [16, 18, 21],
                  color: "#7C8087",
                }}
                component="div"
              >
                Value + Convenience + Flexibility
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{
                mb: 8,
                ...(inView && {
                  animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(12,1fr)",
                    md: "repeat(3,1fr)",
                  },
                  gridTemplateRows: "min-content",
                  gap: { xs: "0px 16px", md: "16px 32px" },
                }}
              >
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <VectorA width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        Flex Resident Rewards
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Find your home and enjoy the best resident benefits like cashback, rewards, and discounts just for paying rent.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <VectorB width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Boost your credit score
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Flex+E+rent's credit engine can supercharge your credit score for free.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <VectorC width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Network of turnkey apartments
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Just show up and start living, the rest is taken care of.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorF width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        No upfront costs or move-in costs
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        No legal fees, no broker fees, no application fees, no security deposits, no furniture costs, none of that.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorE width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Flexible lease
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Join us for as little as 30 days, to as long as 24 months.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorD width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        All Inclusive
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Enjoy a service that is simple, transparent, and stress-free. Utilities, Wi-Fi, and cleaning costs are included in one price.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorA width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Co-sharing option
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Choose between our private spaces, or living in affordable shared spaces with verified housemates.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorB width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Virtual inspection
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Every home has a complete 3D tour. Take your time exploring so you can book with confidence.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    gridColumn: { xs: "span 12", md: "span 1" },
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Grid container columns={{ md: 6 }}>
                    <Grid item xs={12}>
                      <VectorB width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Flexible Payment
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Axiforma",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Subscribe to any of our flexible payment plans & pay your rent with ease.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </InView>
  );
}
