import React from "react";
import { HumburgerMenu, Flexerent } from "../assets";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { useHistory, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { HashLink } from "react-router-hash-link";
export default function CustomAppBar(props) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/landLords",
    exact: true,
  });
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(!isDrawerOpen);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      {/* <List>
        {["How it works", "About us", "Landlords", "Get early Access"].map(
          (text) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          )
            
        )}
        
      </List> */}
      <list>
        {/* <Box sx={{ flexGrow: 1 }}>
        <HashLink
          style={{
            textDecoration: "none",
            color: "#000000",
            fontSize: "0.875rem",
            lineHeight: "1.75",
            letterSpacing: "-0.28px",
            paddingBottom: "8px",
          }}
          to="/#how-it-works"
        >
          How it works
        </HashLink>
        </Box> */}

        {/* <Box sx={{ flexGrow: 1 }}>
          <Button
            sx={{
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
            }}
            onClick={() => history.push("/#how-it-works")}
          >
            How it works
          </Button>
        </Box> */}
        <Box sx={{ flexGrow: 1 }}>
          <Button
            sx={{
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
            }}
            onClick={() => history.push("/#how-it-works")}
          >
            How it works
          </Button>
        </Box>


        <Box sx={{ flexGrow: 1 }}>
          <Button
            sx={{
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
            }}
            onClick={() => history.push("/")}
          >
            Renter
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Button
            sx={{
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
            }}
            onClick={() => history.push("/landLords")}
          >
            Landlords
          </Button>
        </Box>
        
        <Box sx={{ flexGrow: 1 }}>
          <Button
            sx={{
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
            }}
            // onClick={() => history.push("/#tenant-getting-started")}
          >
            Get Early Access
          </Button>
        </Box>


      </list>

      
    </Box>
  );
  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: !!match ? "#ffffff" : "#fffd5f" }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              pl: [1, 1, 3, 13.25],
              pr: [2, 4, 6, 8, 14.75],
            }}
          >
            <Box sx={{ display: "flex", flexGrow: 3, alignItems: "center" }}>
              <Box
                sx={{
                  width: "130px",
                  height: "42px",
                  "&:hover": {
                    pointer: "cursor",
                  },
                }}
                onClick={() => {
                  history.push("./");
                }}
              >
                <Flexerent width={"100%"} height={"100%"} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: { xs: "flex-end", md: "flex-start" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "inline-flex" },
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                >
                  <HashLink
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      fontSize: "0.875rem",
                      lineHeight: "1.75",
                      letterSpacing: "-0.28px",
                      paddingBottom: "8px",
                    }}
                    to="/#how-it-works"
                  >
                    How it works
                  </HashLink>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Button
                    sx={{
                      "&.MuiButton-containedPrimary": {
                        fontSize: {
                          xs: "0.40rem",
                          md: "0.53rem",
                          lg: "0.75rem",
                        },
                      },
                    }}
                    onClick={() => history.push("/")}
                  >
                    Renter
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Button
                    sx={{
                      "&.MuiButton-containedPrimary": {
                        fontSize: {
                          xs: "0.40rem",
                          md: "0.53rem",
                          lg: "0.75rem",
                        },
                      },
                    }}
                    onClick={() => history.push("/landLords")}
                  >
                    Landlords
                  </Button>
                </Box>
                <Box
                  sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
                >
                  <HashLink
                    style={{
                      color: "#000000",
                      textDecoration: "none",
                      fontSize: "0.875rem",
                      lineHeight: "1.75",
                      letterSpacing: "-0.28px",
                      paddingBottom: "8px",
                    }}
                    to= {`${
                      match
                        ? "/LandLords#landLord-getting-started"
                        : "/#tenant-getting-started"
                    }`}
                  >
                    Get early Access
                  </HashLink>
                </Box>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer()}
                >
                  <HumburgerMenu width={"30px"} height={"30px"} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer()}>
        {list()}
      </Drawer>
    </>
  );
}
