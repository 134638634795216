import { React, useState } from "react";
import { ThemeProvider } from "@mui/system";
import { theme } from "./theme";
import "./App.css";
import { CssBaseline } from "@mui/material";
import LandingPage from "./Pages/LandingPage";
import LandLords from "./Pages/LandLords";
import { Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer";
import CustomAppBar from "./Components/CustomAppBar";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <CustomAppBar />
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/landLords">
            <LandLords />
          </Route>
        </Switch>
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default App;
