import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { InView } from "react-intersection-observer";
import { toLeft, toRight } from "../../utils/animation";
import { HashLink } from "react-router-hash-link";

export default function SaveExpenses() {
  return (
    <InView rootMargin="200px 0px 0px 0px" threshold={0.1}>
      {({ inView, ref, entry }) => {
        return (
          <Grid
            ref={ref}
            container
            sx={{
              pl: [1, 4, 6, 16.25],
              pr: [1, 4, 6, 14.75],
              pt: [8, 8, 12],
              pb: 4,
            }}
          >
            <Grid
              item
              xs={12}
              container
              sx={{
                ...(inView && {
                  animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                }),
                mb: [2, 2, 6],
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Axiforma, sans-serif",
                  fontWeight: 700,
                  fontSize: [18, 20, 32, 44],
                  color: "#1D293F",
                  lineHeight: 1,
                }}
                component="div"
              >
                Save Up-to 45% in upfront expenses when
                <br className="big-screen-break" /> you live the flexi-way
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              columnSpacing={{ xs: "0px", md: 8 }}
              rowSpacing={{ xs: 4, md: "0px" }}
              sx={{
                mb: { xs: 8, md: 25 },
              }}
            >
              <Grid
                container
                item
                xs={12}
                md={6}
                xl={4}
                alignItems={{ md: "center" }}
                sx={{
                  ...(inView && {
                    animation: {
                      md: `${toLeft} 3s ease 1s 1 backwards`,
                    },
                  }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    rowGap: [2, 2, 2, 2, 4],
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Axiforma, sans-serif",
                        fontWeight: 600,
                        fontSize: [18, 18, 18, 18, 24],
                        lineHeight: 1,
                      }}
                      component="div"
                    >
                      FURNISHED.AFFORDABLE. ACCESSIBLE
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Axiforma, sans-serif",
                        fontWeight: 400,
                        fontSize: [14, 16, 14, 16, 18],
                        lineHeight: 1.9,
                      }}
                      component="div"
                    >
                      For members, Flex+E+Rent helps to make renting a home more accessible and affordable by lowering the barrier to entry, saving residents millions of Naira on both upfront & move-in costs.
                    </Typography>
                  </Box>
                  <Box>
                    <Box>
                      <HashLink smooth to= "/#tenant-getting-started">
                        <Button
                          sx={{
                            textTransform: "initial",
                            "&.MuiButton-containedPrimary": {
                              fontSize: {
                                xs: "0.40rem",
                                md: "0.53rem",
                                lg: "0.75rem",
                              },
                            },
                            padding: { md: "16px 24px" },
                          }}
                          variant="contained"
                        >
                          Get Early Access
                        </Button>
                      </HashLink>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                container
                justifyContent="center"
                item
                xs={12}
                md={6}
                sx={{
                  ...(inView && {
                    animation: {
                      md: `${toRight} 3s ease 1s 1 backwards`,
                    },
                  }),
                }}
              >
                <Box
                  component="img"
                  alt="an image of a room"
                  src={require("../../assets/RoomImg.jpg")}
                  sx={{
                    width: 530,
                    height: 479,
                    maxHeight: { xs: 260, sm: 400, md: 310, lg: 380, xl: 440 },
                    maxWidth: { xs: 300, sm: 500, md: 370, lg: 420, xl: 489 },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{
                backgroundColor: " #FFFD5F",
                borderRadius: 2,
                height: [100, 120, 150],
                paddingTop: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  px: [2, 4, 6, 8],
                  columnSpacing: [1, null],
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: [3, 3, 2],
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Sen",
                      fontWeight: 600,
                      fontSize: [10, 12, 14, 24, 32],
                      color: "#000000",
                      letterSpacing: [1, 1, 1, 3],
                    }}
                  >
                    Let Us Help You Save Money + Save Time + Stay Flexible
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <HashLink smooth to= "/#tenant-getting-started">
                    <Button
                      sx={{
                        textTransform: "initial",
                        borderRadius: "0px",
                        "&.MuiButton-containedPrimary": {
                          fontSize: {
                            xs: "0.35rem",
                            md: "0.53rem",
                            lg: "0.75rem",
                          },
                          ["@media (max-width:600px)"]: {
                            height: "22px",
                            lineHeight: "unset",
                            padding: "unset",
                            borderRadius: "4px",
                          },
                        },
                      }}
                      variant="contained"
                    >
                      Get Early Access
                    </Button>
                  </HashLink>
                </Box>
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </InView>
  );
}
