import { keyframes } from "@mui/system";

export const toLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity:0;
  }
  to {
    transform: translateX(0%);
    opacity:1;

  }
`;

export const toRight = keyframes`
    from {
    transform: translateX(100%);
    opacity:0;
  }
  to {
    transform: translateX(0%);
    opacity:1;

  }
  `;
