import React from "react";
import { InView } from "react-intersection-observer";
import { toLeft, toRight } from "../../utils/animation";
import {
  GettingStartedA,
  GettingStartedC,
  GettingStartedB,
} from "../../assets";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function HelpPage() {
  return (
    <InView rootMargin="-100px 0px 0px 0px">
      {({ inView, ref, entry }) => {
        return (
          <Grid
            id="how-it-works"
            container
            ref={ref}
            direction="column"
            justifyContent="center"
            spacing={4}
            sx={{
              backgroundColor: "#FFFF001A",
              pl: [1, 4, 6, 16.25],
              pr: [1, 4, 6, 14.75],
              pt: [8, 8, 12],
              pb: 4,
            }}
          >
            <Grid
              item
              xs={12}
              container
              sx={{
                ...(inView && {
                  animation: { md: `${toLeft} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Axiforma",sans-serif',
                  fontWeight: 700,
                  fontSize: [21, 24, 32, 48],
                }}
                gutterBottom
                // align="center"
              >
                Let Us Help You Save Money + Save Time
                <br className="big-screen-break" />+ Stay Flexible in easy
                steps.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              sx={{
                mb: [10, 14],
                ...(inView && {
                  animation: { md: `${toRight} 3s ease 1s 1 backwards` },
                }),
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(12,1fr)",
                    md: "repeat(3,140px)",
                  },
                  gridTemplateRows: "min-content",
                  gap: {
                    xs: "0px 24px",
                    md: "0px 250px",
                    lg: "0px 300px",
                  },
                }}
              >
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <GettingStartedA width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        Find your home
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Browse our bouquet of homes and choose your favorite
                        space. See inside. Take a 3D digital tour. All without
                        leaving your couch.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <GettingStartedB width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Book a space
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Look through our available options and make your
                        preferred selection.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ gridColumn: { xs: "span 12", md: "span 1" } }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <GettingStartedC width={"40px"} height={"50px"} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Pay and move-in
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: '"Circular Std",sans-serif',
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#7C8087",
                        }}
                      >
                        Make payment and prepare to move into your new home
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </InView>
  );
}
