import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
export default function Footer() {
  return (
    <Grid
      container
      sx={{
        mt: 5,
        background: "#1D293F",
        color: "#ffffff",
        height: {
          xs: "auto",
          md: "400px",
        },
        paddingTop: [10, null],
        paddingBottom: 10,
        pl: [1, 4, 6, 16.25],
        pr: [1, 4, 6, 14.75],
        rowGap: 2,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "Axiforma",
            fontWeight: 700,
            fontSize: [21, 24, 32, 48],
            color: "#FFFFFF",
            width: "100%",
          }}
          component="div"
        >
          Join the waitlist for Membership & be the first to know about our
          newest apartments
        </Typography>
        <Box sx={{ mb: [1, 2] }} />
        <Button
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#000000",
            "&.MuiButton-containedPrimary": {
              fontSize: {
                xs: "0.40rem",
                md: "0.53rem",
                lg: "0.75rem",
              },
              textTransform: "none",
            },
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
          variant="primary"
        >
          Join the waitlist
        </Button>
      </Box>
    </Grid>
  );
}
