import React, { useState } from 'react'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

function TenantForm({ status, message, onValidated }) {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleValidation = () => {
      if (email === "" ) {
        return false
      }
      if (firstName === "") {
        return false
      }
      if (lastName === "") {
        return false
      }
      if (phoneNumber === "") {
        return false
      }
      if ( !email.includes("@")) {
        return false
      }
      return true
    }

    const handleSubmit = (event) => {
    event.preventDefault();
            if(handleValidation()){
                onValidated({
                  EMAIL: email,
                  FNAME: firstName,
                  LNAME: lastName,
                  PHONE: phoneNumber,
              });
              setFirstName("")
              setLastName("")
              setEmail("")
              setPhoneNumber("")
            }       
    }

  return (
    <form onSubmit={handleSubmit}>

        {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <Box sx={{ mb: [2, 4, 6, 8] }} />
        <Grid
          item
          xs
          container
          rowSpacing={{ xs: 1, md: 2 }}
          columnSpacing={{ md: 4 }}
        >

          <Grid item xs={12} md={6}>
            <TextField
              id="mce-FNAME"
              label="First name"
              variant="outlined"
              sx={{ display: "flex" }}
              onChange={handleFirstNameChange}
              value={firstName}
              name="FNAME"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="mce-LNAME"
              label="Last name"
              variant="outlined"
              sx={{ display: "flex" }}
              onChange={handleLastNameChange}
              value={lastName}
               name="LNAME" 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="mce-EMAIL"
              label="Email"
              variant="outlined"
              sx={{ display: "flex" }}
              onChange={handleEmailChange}
              value={email}
              name="EMAIL"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="mce-PHONE"
              label="Phone No"
              variant="outlined"
              sx={{ display: "flex" }}
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
              name="PHONE"
            />
          </Grid>
        </Grid>
        <Box sx={{ mb: [2, 4, 6, 8] }} />

        <Grid item xs={12} sx={{ px: [1, 2, 4] }}>
          <Typography
            sx={{
              fontFamily: '"Circular Std",sans-serif',
              color: "#8C97AC",
              fontSize: [10, 12, 14],
              fontWeight: 300,
            }}
            component="div"
          >
            By submitting this form, I agree to receive emails, telephone calls,
            promotional offers, marketing materials and other communications from
            Flexerent (including its subsidiaries, affiliates, agents, and
            assigns) (“Flexerent”) about its products and services. I understand
            that I can withdraw my authorization at any time. I also understand
            that my information will not be shared with any other parties outside
            of Flexerent.
          </Typography>
        </Grid>
        <Box sx={{ mb: [2, 4, 6, 8] }} />

        <Grid item container justifyContent="center" xs={12}>
          <Button
            sx={{
              backgroundColor: "#000000",
              color: "#ffffff",
              textTransform: "initial",
              "&.MuiButton-containedPrimary": {
                fontSize: {
                  xs: "0.40rem",
                  md: "0.53rem",
                  lg: "0.75rem",
                },
              },
              "&:hover": {
                backgroundColor: "#B2B2B2",
                color: "#000000",
              },
            }}
            variant="contained"
            type="submit"
            >
            Submit
          </Button>
        </Grid>
      </form>
  )
}

export default TenantForm
