import React, { useState } from 'react'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "@mui/material";


function LandlordForm({ status, message, onValidated } ) {
  const housingUnits = [
    "Studio",
    "Apartment",
    "Flat",
    "Bungalow",
    "Duplex",
    "Miniflat",
    "Penthouse",
  ];

  const initialState = housingUnits[0];

  const [housingUnit, setHousingUnit] = React.useState("Studio");

  const handleHousingUnitChange = (event) => {
    setHousingUnit(event.target.value);
  };

  const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [noOfApartments, setNoOfApartments] = useState('');
    const [apartmentToBeFurnished, setApartmentToBeFurnished] = useState('');
    const [noOfBedrooms, setNoOfBedrooms] = useState('');
    const [area, setArea] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleNumOfApartmentsChange = (e) => {
        setNoOfApartments(e.target.value)
    }

    const handleApartmentsNumChange = (e) => {
        setApartmentToBeFurnished(e.target.value)
    }

    const handleNumOfBedroomChange = (e) => {
        setNoOfBedrooms(e.target.value)
    }

    const handleAreaChange = (e) => {
        setArea(e.target.value)
    }

    const handleCompanyWebChange = (e) => {
        setCompanyWebsite(e.target.value)
    }

    const handleValidation = () => {
      if (email === "" ) {
        return false
      }
      if (firstName === "") {
        return false
      }
      if (lastName === "") {
        return false
      }
      if (phoneNumber === "") {
        return false
      }
      if (noOfApartments === "") {
        return false
      }
      if (apartmentToBeFurnished === "") {
        return false
      }
      if (noOfBedrooms === "") {
        return false
      }
      if (area === "") {
        return false
      }
      if (companyWebsite === "") {
        return false
      }
      if (housingUnit === "") {
        return false
      }
      if ( !email.includes("@")) {
        return false
      }
      return true
    }

    const handleSubmit = (event) => {
    event.preventDefault();
            if(handleValidation){
              onValidated({
                  EMAIL: email,
                  FNAME: firstName,
                  LNAME: lastName,
                  PHONE: phoneNumber,
                  NAPPARTMENT: noOfApartments,
                  AFURNISHED: apartmentToBeFurnished,
                  NBEDROOMS: noOfBedrooms,
                  AREA: area,
                  CWEBSITE: companyWebsite,
                  HUNIT: housingUnit
              });
              setFirstName("")
              setLastName("")
              setEmail("")
              setPhoneNumber("")
              setApartmentToBeFurnished("")
              setNoOfApartments("")
              setNoOfBedrooms("")
              setArea("")
              setCompanyWebsite("")
              setHousingUnit(initialState)
            }
            
    }


  return (
   <form onSubmit={handleSubmit}>

       {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {status === "error" && (
          <div 
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="mc__alert mc__alert--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

      <Box sx={{ mb: [2, 4, 6, 8] }} />
      <Grid
        item
        xs
        container
        rowSpacing={{ xs: 1, md: 2 }}
        columnSpacing={{ md: 4 }}
      >

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="First name"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleFirstNameChange}
            value={firstName}
            name="FNAME"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Last name"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleLastNameChange}
            value={lastName}
            name="LNAME"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleEmailChange}
            value={email}
            name="EMAIL"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Phone No"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handlePhoneNumberChange}
            value={phoneNumber}
            name="PHONE"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="How many apartment communities do you own?"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleNumOfApartmentsChange}
            value={noOfApartments}
            name="NAPPARTMENT"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Are your units available to be furnished by Landing?"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleApartmentsNumChange}
            value={apartmentToBeFurnished}
            name="AFURNISHED"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Number of bedrooms"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={handleNumOfBedroomChange}
            value={noOfBedrooms}
            name="NBEDROOMS"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Area"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={ handleAreaChange}
            value={area}
            name="AREA"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Company Website"
            variant="outlined"
            sx={{ display: "flex" }}
            onChange={ handleCompanyWebChange}
            value={companyWebsite}
            name="CWEBSITE"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-select-housingUnit"
            select
            label="Select"
            value={housingUnit}
            name="HUNIT"
            onChange={handleHousingUnitChange}
            // helperText="Housing Unit"
            sx={{ width: "100%" }}
          >
            {housingUnits.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box sx={{ mb: [2, 4, 6, 8] }} />

      <Grid item xs={12} sx={{ px: [1, 2, 4] }}>
        <Typography
          sx={{
            fontFamily: '"Circular Std",sans-serif',
            color: "#8C97AC",
            fontSize: [10, 12, 14],
            fontWeight: 300,
          }}
          component="div"
        >
          By submitting this form, I agree to receive emails, telephone calls,
          promotional offers, marketing materials and other communications from
          Flexerent (including its subsidiaries, affiliates, agents, and
          assigns) (“Flexerent”) about its products and services. I understand
          that I can withdraw my authorization at any time. I also understand
          that my information will not be shared with any other parties outside
          of Flexerent.
        </Typography>
      </Grid>
      <Box sx={{ mb: [2, 4, 6, 8] }} />

      <Grid item container justifyContent="center" xs={12}>
        <Button
          sx={{
            backgroundColor: "#000000",
            color: "#ffffff",
            textTransform: "initial",
            "&.MuiButton-containedPrimary": {
              fontSize: {
                xs: "0.40rem",
                md: "0.53rem",
                lg: "0.75rem",
              },
            },
            "&:hover": {
              backgroundColor: "#B2B2B2",
              color: "#000000",
            },
          }}
          variant="contained"
          type="submit"
        >
          Submit
        </Button>
      </Grid>
    </form>
  )
}

export default LandlordForm